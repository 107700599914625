@font-face {
  font-family: 'GalanoGrotesque-Bold';
  src: url('./fonts/380298_0_0.eot');
  src: url('./fonts/380298_0_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/380298_0_0.woff2') format('woff2'),
    url('./fonts/380298_0_0.woff') format('woff'),
    url('./fonts/380298_0_0.ttf') format('truetype');
}

/* start Bootstrap variable overrides */

// colours
$binit-azure: #198ffb;
$binit-orange: #ff5900;
$binit-orange-40: #ffbd9a;
$binit-dust: #e1dce9;
$binit-grey: #323642;
$binit-grey-80: #62626a;
$binit-grey-60: #8a898f;
$binit-grey-5: #f5f5f5;
$binit-purple: #c8247d;
$binit-raspberry: #ff263c;
$primary: $binit-orange;
$secondary: $binit-grey-60;
$success: $binit-orange;
$danger: $binit-raspberry;
$info: $binit-azure;
$headings-color: $binit-grey;
$black: $binit-grey;
$light: $binit-grey-5;
$grey-800: $binit-grey;
$body-color: $binit-grey-80;
$btn-disabled-opacity: 1;
$footer-link-colour: $binit-orange-40;
$input-placeholder-color: $binit-orange-40; //#ffac7f;
$navbar-light-color: $binit-grey;
$navbar-light-active-color: $binit-orange;
$text-muted: #83838a;
$theme-colors: (
  'white': #ffffff,
);

// fonts
$headings-font-family: 'GalanoGrotesque-Bold';
$font-family-base: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-size-base: 1.25rem;
$line-height-base: 1.6;
$headings-line-height: 1.35;
$h2-font-size: 2.375rem;

// spacing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 2,
  ),
  5: (
    $spacer * 3,
  ),
  8: (
    $spacer * 8,
  ),
  9: (
    $spacer * 10,
  ),
);

// borders
$border-radius: 0;
$border-radius-lg: 0;
$border-width: 3px;

// inputs
$input-border-color: $primary;
$input-border-width: 3px;
$input-btn-padding-y: 0.7rem;

// buttons
$input-btn-font-size: 1.25rem;

// custom
$btn-padding-x: 1.5rem;
$navbar-padding-y: 0.375 * $spacer;
$navbar-padding-x: $spacer * 2;
$custom-control-spacer-x: 0;
$custom-control-indicator-border-color: $primary;
$custom-control-indicator-size: 2rem;

/* end Bootstrap variable overrides */

.btn,
.input-group,
.form-group input,
.display-5,
.binit-header-font {
  font-family: $headings-font-family;
}

/* IMPORT BOOTSTRAP */
@import 'bootstrap/scss/bootstrap.scss';

/* IMPORT FONTAWESOME */
@import './fa/scss/fontawesome.scss';
@import './fa/scss/brands.scss';
@import './fa/scss/regular.scss';
@import './fa/scss/solid.scss';

// navbar
.navbar {
  font-size: 0.9 * $font-size-base;
  .btn {
    font-size: 0.9 * $font-size-base;
  }
  .nav-link {
    border-bottom: solid 2px #ffffff00;
    transition: border-color 0.25s;
    &:hover {
      border-bottom: solid 2px $binit-grey;
    }
  }
}

.binit-navbar li {
  margin-bottom: 0;
}

#get-a-quote {
  padding-top: ($spacer / 2) - 0.2rem;
  padding-bottom: ($spacer / 2) - 0.2rem;
  &:hover {
    color: $white;
  }
}

.binit-rows-container-alternating > *:nth-of-type(odd) {
  background-color: #f5f5f5;
}

.binit-white-block {
  background-color: white;
  @include media-breakpoint-down(md) {
    min-height: inherit;
  }
}

.binit-light-gradient-block {
  background: linear-gradient(to bottom, #f5f5f5 0%, #e1dce9 100%);
}

.binit-chunky-radio {
  box-shadow: 0px 4px 6px 0px $binit-dust;
  border-style: none;
}

/* start footer */

#footer {
  li {
    a {
      color: $footer-link-colour;
    }
  }
  .footer-link {
    font-family: 'GalanoGrotesque-Bold';
    color: #adafb3;
  }
}

/* end footer */

/* start button overrides */

.btn {
  &.btn-primary {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: $white;
    }
  }
  text-align: center;
  &.disabled,
  &:disabled {
    background-color: $binit-grey-60;
    border-color: $binit-grey-60;
  }
}

.btn-info {
  background: rgb(70, 166, 253);
  background: linear-gradient(
    0deg,
    rgba(70, 166, 253, 1) 0%,
    rgba(25, 143, 251, 1) 100%
  );
}

.btn-white {
  color: $info;
}

/* end button overrides */

a {
  font-weight: 700;
}

.text-light,
.text-white {
  a {
    color: $binit-orange-40;
  }
}

input {
  text-align: center;
}

// override for rc-calendar
.rc-calendar {
  font-size: inherit;
  font-family: inherit !important;
  width: 316px;
  .rc-calendar-year-select,
  .rc-calendar-month-select,
  .rc-calendar-day-select {
    font-size: inherit;
  }
  .rc-calendar-cell {
    padding: 8px 8px;
  }
  .rc-calendar-body {
    height: 300px;
  }
}

/* rc-slider */
.binit-chunky-slider {
  margin-bottom: 56px;

  .rc-slider-handle {
    border: none;
    background-color: $info;
    height: 40px;
    width: 40px;
    margin-left: -20px;
    margin-top: -15px;
    &.danger {
      background-color: $danger;
    }
  }

  .rc-slider-rail,
  .rc-slider-track,
  .rc-slider-step {
    background-color: $info;
    height: 10px;
    border-radius: 4px;
    background: linear-gradient(
      to right,
      $info 0%,
      $info 83.3%,
      $danger 83.3%,
      $danger 100%
    );
  }
  .rc-slider-step {
    .rc-slider-dot {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(6) {
        display: block;
        // big blue 50%
        margin-left: -6px;
        height: 25px;
        bottom: -8px;
        border: 5px solid $info;
        background-color: $info;
      }
      &:nth-child(11) {
        display: block;
        // the large red one
        margin-left: -6px;
        height: 25px;
        bottom: -8px;
        border: 5px solid $danger;
        background-color: $danger;
      }
      &:nth-child(12) {
        display: none;
      }
      &:nth-child(13) {
        display: none;
      }
      @include media-breakpoint-down(md) {
        display: block;
        border-radius: 4px;
        border: none;
        background-color: $info;
        height: 16px;
        bottom: -10px;
        width: 5px;
      }
      @include media-breakpoint-up(md) {
        display: block;
        border-radius: 4px;
        border: none;
        background-color: $info;
        height: 16px;
        bottom: -10px;
        width: 5px;
      }
    }
  }
  .rc-slider-mark {
    font-size: $font-size-base * 0.7;
    .rc-slider-mark-text {
      color: $info;
      top: 30px;
      vertical-align: baseline;
      line-height: 10px;
      &:nth-child(1) {
        display: block;
      }
      &:nth-child(6) {
        display: block;
      }
      &:nth-child(11) {
        display: block;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
}
